// export default [
//   {
//     title: "Home",
//     route: "home",
//     icon: "HomeIcon",
//   },

// ];

// Mevcut menü öğeleri
const existingMenuItems = [
  {
    title: "Anasayfa",
    route: "home",
    icon: "HomeIcon",
    action: "read",
    resource: "home",
  },
  {
    title: "Stok",
    icon: "BoxIcon",
    children: [
      {
        title: "Ürün Ekle",
        route: "UrunEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "UrunEkle",
      },
      {
        title: "Mal Kabul",
        route: "MalKabul",
        icon: "TruckIcon",
        action: "read",
        resource: "MalKabul",
      },
      {
        title: "Mal Kabulleri",
        route: "MalKabulleri",
        icon: "ArrowDownIcon",
        action: "read",
        resource: "MalKabulleri",
      },
      {
        title: "Stoklar",
        route: "Stoklar",
        icon: "BoxIcon",
        action: "read",
        resource: "Stoklar",
      },
    ],
  },
  {
    title: "Mağaza",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Satış",
        route: "Satis",
        icon: "ShoppingBagIcon",
        action: "read",
        resource: "Satis",
      },
      {
        title: "Satışlarım",
        route: "Satislarim",
        icon: "ClipboardIcon",
        action: "read",
        resource: "Satislarim",
      },
    ],
  },
  {
    title: "Website",
    icon: "ListIcon",
    children: [
      {
        title: "Website Ürünleri",
        route: "WebsiteProduct",
        icon: "CropIcon",
        action: "read",
        resource: "WebsiteProduct",
      },
      {
        title: "Slider",
        route: "SliderPage",
        icon: "ImageIcon",
        action: "read",
        resource: "SliderPage",
      },
      {
        title: "İş Ortaklarımız	",
        route: "OurBusinessPartners",
        icon: "ImageIcon",
        action: "read",
        resource: "OurBusinessPartners",
      },
    ],
  },
  {
    title: "Yönetim",
    icon: "SettingsIcon",
    children: [
      {
        title: "Sayfa Ekle",
        route: "SayfaEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "SayfaEkle",
      },
      {
        title: "Modul Ekle",
        route: "ModulEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "ModulEkle",
      },
      {
        title: "Rol Ekle",
        route: "RolEkle",
        icon: "PlusIcon",
        action: "read",
        resource: "RolEkle",
      },
      {
        title: "Rol Yetki Yonetimi",
        route: "RolYetkiYonetimi",
        icon: "SunsetIcon",
        action: "read",
        resource: "RolYetkiYonetimi",
      },
    ],
  },

  // Diğer menü öğeleri buraya eklenebilir
];

function createCombinedMenu() {
  const combinedMenuItems = [...existingMenuItems];

  return combinedMenuItems;
}

// Oluşturulan menüyü dışa aktar
export default createCombinedMenu();
