<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <h6 style="margin-top: 10px">
      <b> {{ company }}</b>
    </h6>
    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">{{ fullName }}</p>
            <span class="user-status">{{ title }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="settings()"
        >
          <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
          <span>Settings</span>
        </b-dropdown-item>
        <!-- <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="pricing()"
        >
          <feather-icon size="16" icon="CreditCardIcon" class="mr-50" />
          <span>Pricing</span>
        </b-dropdown-item> -->
        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Exit</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      fullName: "",
      title: "",
      company: "",
      avatar:
        "https://www.shareicon.net/data/2017/02/15/878685_user_512x512.png",
    };
  },
  created() {
    var userData = JSON.parse(localStorage.getItem("HPUserData"));
    this.fullName = userData.fullName;
    this.title = userData.title;
    this.company = userData.customer.customerName;
    if (
      userData.avatar != null ||
      userData.avatar != undefined ||
      userData.avatar != ""
    ) {
      this.avatar = userData.avatar;
    }
    if (this.avatar == "") {
      this.avatar =
        "https://www.shareicon.net/data/2017/02/15/878685_user_512x512.png";
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("HPUserData");
      localStorage.removeItem("HPaccessToken");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "login" });
    },
    settings() {
      // Redirect to settings page

      this.$router.push({ name: "settings" });
    },
    pricing() {
      // Redirect to pricing page

      this.$router.push({ name: "pricing" });
    },
  },
};
</script>
